import { createContext, useEffect, useReducer } from 'react';

import { useWallet } from './WalletContext';
import user from 'services/user';
import stats from 'services/stats';

const initialState = {
  data: [],
  count: [],
  leaderboard: [],
  loading: false,
};

const ACTIONS = {
  SET_DATA: 'SET_DATA',
  SET_LOADING: 'SET_LOADING',
  SET_LEADERBOARD: 'SET_LEADERBOARD',
  SET_COUNT: 'SET_COUNT',
};

const NftVolumeReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case ACTIONS.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case ACTIONS.SET_LEADERBOARD: {
      return {
        ...state,
        leaderboard: action.payload,
      };
    }
    case ACTIONS.SET_COUNT: {
      return {
        ...state,
        count: action.payload,
      };
    }
  }

  throw Error('Unknown action: ' + action.type);
};

export const NftVolumeContext = createContext(null);

export const NftVolumeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NftVolumeReducer, initialState);
  const { address, isConnected, isAuthenticated } = useWallet();

  const fetchVolumeData = async () => {
    setLoading(true);
    try {
      const data = await user.getNftVolume();
      dispatch({ type: ACTIONS.SET_DATA, payload: data });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLeaderboard = async () => {
    setLoading(true);
    const result = await stats.getNftVolumeLeaderboard({ skip: 0, limit: 50 });
    setCount(result.count);
    setLeaderboard(result.data);
    setLoading(false);
  };

  const loadMore = async () => {
    if (!state.loading) {
      setLoading(true);
      const result = await stats.getNftVolumeLeaderboard({
        skip: state.leaderboard.length,
        limit: 50,
      });
      setLeaderboard([...state.leaderboard, ...result.data]);
      setLoading(false);
    }
  };

  const setLoading = (loading) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: loading });
  };

  const setLeaderboard = (loading) => {
    dispatch({ type: ACTIONS.SET_LEADERBOARD, payload: loading });
  };

  const setCount = (loading) => {
    dispatch({ type: ACTIONS.SET_COUNT, payload: loading });
  };

  useEffect(() => {
    if (address && isConnected && isAuthenticated) {
      fetchVolumeData();
    }
  }, [address, isConnected, isAuthenticated]);

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  return (
    <NftVolumeContext.Provider
      value={{
        state,
        dispatch,
        loadMore,
      }}
    >
      {children}
    </NftVolumeContext.Provider>
  );
};
