import { createContext, useEffect, useReducer } from 'react';

import userService from 'services/user';

import { BONUS_TYPES } from 'constants/bonusS2';
import { useWallet } from './WalletContext';

const initialState = {
  tab: 'overview',
  data: {
    bonuses: [
      {
        name: 'apedIn',
        spotsLeft: 1000,
        level: 7,
        multiplier: 1,
        percentage: 50,
        completed: false,
        expired: false,
      },
      {
        name: 'earlyDegen',
        expiresIn: 855721,
        level: 1,
        multiplier: 1,
        completed: false,
        expired: false,
      },
      {
        name: 'diamondHands',
        level: 0,
        multiplier: 1,
        completed: false,
        total: 0,
        nextLevel: 200,
        percentage: 0,
      },
      {
        name: 'zealy',
        multiplier: 1,
        zealyJoinDate: null,
        completed: false,
        expired: false,
      },
      {
        name: 'referral',
        multiplier: 1,
        completed: false,
        expired: false,
      },
      {
        name: 'spreadTheWord',
        multiplier: 1,
        level: 0,
        completed: false,
        referralCount: 0,
        expired: false,
      },
      {
        name: 'dailyChecks',
        multiplier: 1,
        completed: false,
        streak: 1,
        gold: 0,
        percentage: 0,
        progress: 0,
      },
      {
        name: 'powerTweet1',
        number: 1,
        multiplier: 1,
        completed: false,
      },
      {
        name: 'powerTweet2',
        number: 2,
        multiplier: 1,
        completed: false,
      },
      {
        name: 'powerTweet3',
        number: 3,
        multiplier: 1,
        completed: false,
      },
      {
        name: 'powerTweet4',
        number: 4,
        multiplier: 1,
        completed: false,
      },
    ],
  },
  loading: true,
  bonusType: 'all',
  onboarding: false,
  fetchedBonuses: false,
};

const ACTIONS = {
  SET_TAB: 'SET_TAB',
  SET_DATA: 'SET_DATA',
  SET_LOADING: 'SET_LOADING',
  SET_BONUS_TYPE: 'SET_BONUS_TYPE',
  SET_FETCHED_BONUSES: 'SET_FETCHED_BONUSES',
};

const DashboardReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    case ACTIONS.SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_BONUS_TYPE:
      return {
        ...state,
        bonusType: action.payload,
      };
    case ACTIONS.SET_FETCHED_BONUSES:
      return {
        ...state,
        fetchedBonuses: action.payload,
      };
  }

  throw Error('Unknown action: ' + action.type);
};

export const DashboardContext = createContext(null);

export const DashboardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DashboardReducer, initialState);
  const { address, isConnected, isAuthenticated } = useWallet();

  const setTab = (tab) => dispatch({ type: ACTIONS.SET_TAB, payload: tab });

  const setBonusType = (payload) => dispatch({ type: ACTIONS.SET_BONUS_TYPE, payload });

  const fetchInitialData = async () => {
    setLoading(true);
    const data = await userService.getAirdropData();
    dispatch({ type: ACTIONS.SET_DATA, payload: data });
    dispatch({ type: ACTIONS.SET_FETCHED_BONUSES, payload: true });
    setLoading(false);
  };

  const getBonusesByType = (type) => {
    return state.data.bonuses.filter(BONUS_TYPES[type]);
  };

  const setLoading = (loading) => dispatch({ type: ACTIONS.SET_LOADING, payload: loading });

  useEffect(() => {
    if (isConnected && isAuthenticated) {
      fetchInitialData();
    }
    setLoading(false);
  }, [address, isConnected, isAuthenticated]);

  return (
    <DashboardContext.Provider
      value={{
        state,
        dispatch,
        setTab,
        setBonusType,
        getBonusesByType,
        refetchData: fetchInitialData,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
