export const isExternalLink = (path) => {
  if (!path) {
    return false;
  }
  return path.startsWith('https://') || path.startsWith('http://');
};

export const capitalize = (text) => text[0].toUpperCase() + text.slice(1);

export const capitalizeAll = (text) =>
  text.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const makeCumulative = (data, keyItem) => {
  const cumulative = {};

  const sortedData = [...data].sort(
    (a, b) => new Date(a[keyItem]).getTime() - new Date(b[keyItem]).getTime(),
  );
  return sortedData.map((item) => {
    for (const key in item) {
      if (key !== keyItem) {
        cumulative[key] = (cumulative[key] || 0) + item[key];
      }
    }

    return { ...item, ...cumulative };
  });
};
