import React from 'react';
import { MedalGold, MedalSilver, MedalBronze } from 'components/ImageComponent';

const TrophyIcon = React.memo(({ rank }) => {
  switch (Number(rank)) {
    case 1:
      return (
        <div className="w-7 flex justify-center relative group">
          <MedalGold className="text-yellow-400 transform transition-transform group-hover:scale-110" />
          <div className="absolute inset-0 animate-pulse opacity-50">
            <MedalGold className="text-yellow-400" />
          </div>
        </div>
      );
    case 2:
      return (
        <div className="w-7 flex justify-center group">
          <MedalSilver className="text-gray-300 transform transition-transform group-hover:scale-110 hover:text-gray-200" />
        </div>
      );
    case 3:
      return (
        <div className="w-7 flex justify-center group">
          <MedalBronze className="text-amber-600 transform transition-transform group-hover:scale-110 hover:text-amber-500" />
        </div>
      );
    default:
      return <div className="w-7" />;
  }
});

TrophyIcon.displayName = 'TrophyIcon';

export default TrophyIcon;
