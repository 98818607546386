import React from 'react';
import { Box, Typography, styled } from '@mui/material';

import userService from 'services/user';
import { useWallet } from 'context/WalletContext';
import useNotification from 'hooks/useNotification';

import { SparkIcon } from 'components/ImageComponent';
import { PromotionCard, Container } from 'components/PromotionCard';
import { StandardButton, ConnectButton } from 'components/Button';
import { ActivatedButton } from 'components/StyledComponents';

const VerticalFlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  gap: theme.spacing(1.5),
}));

const TopSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

/********************  Main Component  ********************/
const PowerTweetCard = ({ completed, streak, gold, percentage, progress, refetchData }) => {
  const { isAuthenticated } = useWallet();
  const showNotification = useNotification();

  const claim = async () => {
    try {
      await userService.claimDailyCheck();
      refetchData();
    } catch (error) {
      showNotification({ type: 'error', message: error.message });
    }
  };

  return (
    <PromotionCard
      Icon={SparkIcon}
      title="Daily Check-in Reward"
      tooltip="Claim your Daily Check-in Rewards and earn Gold and Boosters"
      completed={completed}
    >
      <Container>
        <VerticalFlexBox>
          <TopSection>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography fontSize={14} fontWeight={500}>
                  Streak Progress: <span style={{ color: '#00E676' }}>{progress}/7 Days</span>
                </Typography>
              </Box>
              <Box>
                <Typography fontSize={14} fontWeight={500}>
                  Streak: <span style={{ color: '#00E676' }}>{streak}x</span>
                </Typography>
              </Box>
            </Box>
            <Typography fontSize={16} fontWeight={600}>
              <span style={{ color: '#F9D202' }}>+{gold} Gold</span> &{' '}
              <span style={{ color: '#00E676' }}>{percentage}% Bonus</span>
            </Typography>
          </TopSection>
          <Box>
            {!isAuthenticated ? (
              <ConnectButton height={40} />
            ) : completed ? (
              <ActivatedButton>Daily Reward Claimed!</ActivatedButton>
            ) : (
              <StandardButton onClick={claim} fullWidth>
                Claim now!
              </StandardButton>
            )}
          </Box>
        </VerticalFlexBox>
      </Container>
    </PromotionCard>
  );
};

export default PowerTweetCard;
