import { createContext, useEffect, useReducer } from 'react';

import stats from 'services/stats';

const initialState = {
  data: [],
  total: 0,
  loading: true,
};

const ACTIONS = {
  SET_DATA: 'SET_DATA',
  SET_LOADING: 'SET_LOADING',
  SET_TOTAL: 'SET_TOTAL',
};

const ReferralProgramContestReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case ACTIONS.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case ACTIONS.SET_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
  }

  throw Error('Unknown action: ' + action.type);
};

export const ReferralProgramContestContext = createContext(null);

export const ReferralProgramContestProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ReferralProgramContestReducer, initialState);
  const fetchLeaderboards = async () => {
    setLoading(true);
    const result = await stats.getReferralProgramContestLeaderboard({ skip: 0, limit: 50 });
    setTotal(result.count);
    setData(result.data);
    setLoading(false);
  };

  const loadMore = async () => {
    const result = await stats.getReferralProgramContestLeaderboard({
      skip: state.data.length,
      limit: 50,
    });

    setData([...state.data, ...result.data]);
    setLoading(false);
  };

  const setLoading = (loading) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: loading });
  };

  const setData = (data) => {
    dispatch({ type: ACTIONS.SET_DATA, payload: data });
  };

  const setTotal = (total) => {
    dispatch({ type: ACTIONS.SET_TOTAL, payload: total });
  };

  useEffect(() => {
    fetchLeaderboards();
  }, []);

  return (
    <ReferralProgramContestContext.Provider
      value={{
        state,
        dispatch,
        loadMore,
      }}
    >
      {children}
    </ReferralProgramContestContext.Provider>
  );
};
