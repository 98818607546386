import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import {
  POWER_TWEET_NUMBER,
  POWER_TWEET_BONUS,
  POWER_TWEET_GOLD_REWARD,
  SEASON_REWARD_POWER_TWEETS,
} from 'constants/bonus';
import { REFERRAL_PROGRAM_URL } from 'constants';
import userService from 'services/user';
import { powerTweetMediaShare, socialMediaShare } from 'utils/socialMediaShare';
import { useWallet } from 'context/WalletContext';
import useNotification from 'hooks/useNotification';
import { RewardsVaultContext } from 'context/RewardsVaultContext';

import { BirdIcon, ThumbCrossedIcon } from 'components/ImageComponent';
import { PromotionCard, FlexBox, Container, BonusAmountText } from 'components/PromotionCard';
import { StandardButton, ConnectButton } from 'components/Button';
import { ActivatedButton } from 'components/StyledComponents';

/********************  Main Component  ********************/
const PowerTweetCard = ({ number, completed, refetchData }) => {
  const { isAuthenticated, twitterUser, address } = useWallet();
  const showNotification = useNotification();
  const started = number <= POWER_TWEET_NUMBER;

  const { state } = useContext(RewardsVaultContext);
  const isSeasonReward = Object.keys(SEASON_REWARD_POWER_TWEETS).includes(String(number));

  const claim = async () => {
    const user_rp_link = `${REFERRAL_PROGRAM_URL}/${twitterUser ?? address}`;
    isSeasonReward
      ? powerTweetMediaShare(user_rp_link, state.currentRewardedTotalGMI, `Power Tweet ${number}`)
      : socialMediaShare(user_rp_link, `Power Tweet ${number}`);
    const response = await userService.claimPowerTweet(number);
    if (response.success) {
      refetchData();
    } else {
      showNotification({
        type: 'error',
        message: response.message,
      });
    }
  };

  return (
    <PromotionCard
      Icon={isSeasonReward ? ThumbCrossedIcon : BirdIcon}
      title={
        isSeasonReward
          ? `S${SEASON_REWARD_POWER_TWEETS[number]} Earnings Bonus`
          : `Power Tweet Bonus ${number}`
      }
      tooltip="Post power tweets for easy booster rewards and 1000 Gold"
      completed={completed}
      started={started}
    >
      <Container>
        <FlexBox flex={1}>
          {isSeasonReward ? (
            <Box width="100%">
              <Typography
                fontSize={completed && isAuthenticated ? 32 : 12}
                textAlign="center"
                lineHeight={1}
              >
                {completed && isAuthenticated
                  ? '🎊'
                  : `Tweet your S${SEASON_REWARD_POWER_TWEETS[number]} GMI Earnings to Claim Bonus`}
              </Typography>
              <Typography
                fontSize={12}
                fontWeight={600}
                textAlign="center"
                marginTop={completed ? 1 : 1.5}
              >
                {completed ? (
                  'HOLD! & Get Ready for More Gains!'
                ) : (
                  <>
                    Special{' '}
                    <Typography component="span" fontSize={20} fontWeight={600}>
                      20%
                    </Typography>{' '}
                    BONUS!{' '}
                  </>
                )}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography fontSize={12} fontWeight={500} marginBottom={1.5}>
                Complete 1 Power Post to claim Bonus
              </Typography>
              {!completed && (
                <Box>
                  <BonusAmountText value={POWER_TWEET_BONUS} gold={POWER_TWEET_GOLD_REWARD} />
                </Box>
              )}
            </Box>
          )}
        </FlexBox>
        {!isAuthenticated ? (
          <ConnectButton height={40}>{started ? null : `Activates soon!`}</ConnectButton>
        ) : completed ? (
          <ActivatedButton>{POWER_TWEET_BONUS}% BONUS Activated</ActivatedButton>
        ) : (
          <StandardButton height={40} onClick={claim}>
            {started ? 'Claim Now!' : `Activates soon!`}
          </StandardButton>
        )}
      </Container>
    </PromotionCard>
  );
};

export default PowerTweetCard;
