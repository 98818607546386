import { ApiClient } from '../ApiClient';

export class UserApi {
  /**
   * Constructs a new UserApi.
   * @alias module:api/UserApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.generalApiInstance;
  }

  /**
   * Get nonce preparing for authentication
   *
   * @param {string} address
   */
  async getNonce(address) {
    return await this.apiClient.callApi('/user/nonce/{address}', 'get', { address }, {}, null);
  }

  /**
   * Authenticate user
   *
   * @param {{address, signature}} body User address is stored here
   */
  async authenticate(body) {
    const refCode = localStorage.getItem('refcode');

    return await this.apiClient.callApi(
      '/user/authenticate',
      'post',
      {},
      {},
      null,
      {},
      { ...body, refCode },
    );
  }

  /**
   * Get twitter user
   */
  async getTwitterUser() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/user/isTwitterExist', 'get', {}, {}, null, headers);
  }

  /**
   * Get airdrop data based on the wallet (simplified, instead of twitter auth)
   */
  async getAirdropData() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/user/airdrop', 'get', {}, {}, null, headers);
  }

  /**
   * Log user out
   */
  async logout() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/user/logout', 'post', {}, {}, null, headers);
  }

  /**
   * Check if user is a member of Zealy
   *
   * @param {{zealyId: string}} body
   */
  async checkZealyMembership(body) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/user/checkZealy', 'post', {}, {}, null, headers, body);
  }

  /**
   * Fetching NFT Hodler and Token Hodler data
   */
  async getHodlerBalance() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/user/holderAirdropBalance', 'get', {}, {}, null, headers);
  }

  /**
   * Fetching NFT Volume Airdrop data
   */
  async getNftVolume() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/user/volumeAirdrop', 'get', {}, {}, null, headers);
  }

  /**
   * Check if Early Degen can be automatically applied
   */
  async checkEarlyDegen() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/user/checkEarlyDegen', 'get', {}, {}, null, headers);
  }

  /**
   * Claim Power Tweet boost
   *
   * @param number serial tweet number
   */
  async claimPowerTweet(number) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi(
      `/user/claimPowerTweet/${number}`,
      'get',
      {},
      {},
      null,
      headers,
    );
  }

  /**
   * Check and autoclaim Referral Program booster
   */
  async checkClaimReferralProgram() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi(
      '/user/checkClaimReferralProgram',
      'get',
      {},
      {},
      null,
      headers,
    );
  }

  async getUserRewards({ season }) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi(
      `/user/seasonVault/${season}`,
      'get',
      {},
      {},
      null,
      headers,
    );
  }

  /**
   * Get user total rewards
   */
  async getTotalRewards() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/user/totalRewards', 'get', {}, {}, null, headers);
  }

  /**
   * Claim user daily rewards
   */
  async claimDailyCheck() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/user/dailyCheck', 'get', {}, {}, null, headers);
  }
}
