import { ApiClient } from '../ApiClient';

export class StatsApi {
  /**
   * Constructs a new StatsApi.
   * @alias module:api/StatsApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.generalApiInstance;
  }

  /**
   * Get leaderboard data
   * @param {Number} skip Numer of items to skip for the result set
   * @param {Number} limit Number of items to retrieve
   * @param {Boolean} includeGraphStats Include graph stats in the response
   *
   */
  async getLeaderboard({ skip, limit, includeGraphStats }) {
    const body = { skip, limit, includeGraphStats };
    return await this.apiClient.callApi('/stats/leaderboard', 'get', {}, body, null);
  }

  /**
   * Get certain account stats
   * @param {string} address
   * @param {Number} skip Numer of items to skip for the result set
   * @param {Number} limit Number of items to retrieve
   * @param {Boolean} includeGraphStats Include graph stats in the response
   *
   */
  async getAccountStats({ address, skip, limit, includeGraphStats }) {
    const body = { skip, limit, includeGraphStats };
    return await this.apiClient.callApi('/stats/account/{address}', 'get', { address }, body, null);
  }

  /**
   * Search address
   * @param {string} address
   */
  async searchForAddress(address) {
    return await this.apiClient.callApi('/stats/account/search', 'get', {}, { address }, null);
  }

  async getS1Leaderboard() {
    return await this.apiClient.callApi('/stats/leaderboardS1', 'get', {}, {}, null);
  }

  async getTraderLeaderboard({ skip = 0, limit = 100 }) {
    return await this.apiClient.callApi(
      '/stats/traderLeaderboard',
      'get',
      {},
      { skip, limit },
      null,
    );
  }

  async getCreatorLeaderboard({ skip = 0, limit = 100 }) {
    return await this.apiClient.callApi(
      '/stats/creatorLeaderboard',
      'get',
      {},
      { skip, limit },
      null,
    );
  }

  async getXLeaderboard({ skip = 0, limit = 100 }) {
    return await this.apiClient.callApi('/stats/xLeaderboard', 'get', {}, { skip, limit }, null);
  }

  async getTokenHodlerLeaderboard({ skip = 0, limit = 100 }) {
    return await this.apiClient.callApi(
      '/stats/tokenHodlerLeaderboard',
      'get',
      {},
      { skip, limit },
      null,
    );
  }

  async getNftHodlerLeaderboard({ skip = 0, limit = 100 }) {
    return await this.apiClient.callApi(
      '/stats/nftHodlerLeaderboard',
      'get',
      {},
      { skip, limit },
      null,
    );
  }

  async getNftVolumeLeaderboard({ skip = 0, limit = 100 }) {
    return await this.apiClient.callApi(
      '/stats/nftVolumeLeaderboard',
      'get',
      {},
      { skip, limit },
      null,
    );
  }

  async getZealyLeaderboard({ skip = 0, limit = 100 }) {
    return await this.apiClient.callApi(
      '/stats/zealyLeaderboard',
      'get',
      {},
      { skip, limit },
      null,
    );
  }

  async getPortfolioLeaderboard({ skip = 0, limit = 100 }) {
    return await this.apiClient.callApi(
      '/stats/portfolioLeaderboard',
      'get',
      {},
      { skip, limit },
      null,
    );
  }

  async getPowerTweetLeaderboard({ skip = 0, limit = 100 }) {
    return await this.apiClient.callApi(
      '/stats/powerTweetLeaderboard',
      'get',
      {},
      { skip, limit },
      null,
    );
  }

  async getListingLeaderboard({ skip = 0, limit = 100 }) {
    return await this.apiClient.callApi(
      '/stats/listingLeaderboard',
      'get',
      {},
      { skip, limit },
      null,
    );
  }

  async getReferralProgramContestLeaderboard({ skip = 0, limit = 100 }) {
    return await this.apiClient.callApi(
      '/stats/referralProgramContest',
      'get',
      {},
      { skip, limit },
      null,
    );
  }
}
