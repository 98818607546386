import { createContext, useContext, useEffect, useMemo, useCallback } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import statsService from 'services/stats';
import { formatChartDate } from 'utils/date';

const LeaderboardS1Context = createContext();
export const useLeaderboardS1Context = () => useContext(LeaderboardS1Context);

/********************  Context Provider ********************/
export const LeaderboardS1Provider = ({ children }) => {
  const [isLoading, setLoading] = useStateIfMounted(true);
  const [topAffiliates, setTopAffiliates] = useStateIfMounted({
    topRanking: [],
    graphData: {},
    perPage: 20,
    currentPage: 1,
  });
  const [s1Leaderboard, setS1Leaderboard] = useStateIfMounted({
    ranking: [],
    graph: {},
    totalRewards: 0,
    totalReferrals: 0,
  });

  const { topRanking, graphData, perPage, currentPage } = topAffiliates;

  const fetchingParams = {
    skip: (currentPage - 1) * perPage,
    limit: perPage,
    includeGraphStats: true,
  };

  useEffect(() => {
    getS1LeaderboardData();
  }, []);

  const getS1LeaderboardData = async () => {
    try {
      const data = await statsService.getS1Leaderboard();
      const formattedData = {
        ranking: [],
        graph: {},
        totalRewards: 0,
        totalReferrals: 0,
      };
      if (data) {
        formattedData.totalRewards = data.referralRewards.rewardGraphStats.weekStat.totalEarnings;
        formattedData.totalReferrals =
          data.referralRewards.referralGraphStats.weekStat.totalReferrals;
        formattedData.ranking = data.topAffiliates;
        formattedData.graph = {
          rewardDaily: data.referralRewards.rewardGraphStats.dayStat.rewards.map((obj) => {
            const { earnings: value, date: time } = obj;
            return { value, time: formatChartDate(time) };
          }),
          rewardWeekly: data.referralRewards.rewardGraphStats.weekStat.rewards.map((obj) => {
            const { earnings: value, date: time } = obj;
            return { value, time: formatChartDate(time) };
          }),
          referralDaily: data.referralRewards.referralGraphStats.dayStat.referrals.map((obj) => {
            const { referrals: value, date: time } = obj;
            return { value, time: formatChartDate(time) };
          }),
          referralWeekly: data.referralRewards.referralGraphStats.weekStat.referrals.map((obj) => {
            const { referrals: value, date: time } = obj;
            return { value, time: formatChartDate(time) };
          }),
        };
      }
      setS1Leaderboard(formattedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTopAffiliates();
  }, [perPage, currentPage]);

  const getTopAffiliates = async () => {
    setLoading(true);
    try {
      const response = await statsService.getLeaderboard(fetchingParams);
      setTopAffiliates({
        ...topAffiliates,
        topRanking: formatResponse(response.topAffiliates),
        graphData: response.referralRewards,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatResponse = (response) => {
    const formattedData = response
      .filter(({ totalReferrals }) => totalReferrals > 0)
      .map((item, index) => ({
        index: index + 1 + perPage * (currentPage - 1),
        ...item,
      }));
    return formattedData;
  };

  const handleNextPage = useCallback(() => {
    setTopAffiliates({ ...topAffiliates, currentPage: currentPage + 1 });
  }, [topAffiliates]);

  const handlePreviousPage = useCallback(() => {
    setTopAffiliates({ ...topAffiliates, currentPage: currentPage - 1 });
  }, [topAffiliates]);

  const handlePerPage = useCallback(
    (value) => setTopAffiliates({ ...topAffiliates, perPage: value }),
    [topAffiliates],
  );

  const referralGraphData = useMemo(
    () => ({
      totalReferrals: graphData?.referralGraphStats?.weekStat?.totalReferrals,
      daily: graphData?.referralGraphStats?.dayStat?.referrals.map((obj) => {
        const { referrals: value, date: time } = obj;
        return { value, time: formatChartDate(time) };
      }),
      weekly: graphData?.referralGraphStats?.weekStat?.referrals.map((obj) => {
        const { referrals: value, date: time } = obj;
        return { value, time: formatChartDate(time) };
      }),
    }),
    [graphData],
  );
  const rewardsGraphData = useMemo(
    () => ({
      totalRewards: graphData?.rewardGraphStats?.weekStat?.totalEarnings,
      daily: graphData?.rewardGraphStats?.dayStat?.rewards.map((obj) => {
        const { earnings: value, date: time } = obj;
        return { value, time: formatChartDate(time) };
      }),
      weekly: graphData?.rewardGraphStats?.weekStat?.rewards.map((obj) => {
        const { earnings: value, date: time } = obj;
        return { value, time: formatChartDate(time) };
      }),
    }),
    [graphData],
  );

  return (
    <LeaderboardS1Context.Provider
      value={{
        isLoading,
        topRanking,
        graphData,
        referralGraphData,
        rewardsGraphData,
        perPage,
        currentPage,
        handleNextPage,
        handlePreviousPage,
        handlePerPage,
        s1Leaderboard,
      }}
    >
      {children}
    </LeaderboardS1Context.Provider>
  );
};
