import { useContext, useMemo } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Box, Typography, styled } from '@mui/material';

import { getLevelByXP, getLevel } from 'constants/bonusS2';

import { DashboardContext } from 'context/DashboardContext';
import { FlexCenter } from 'components/StyledComponents';
import { InfoIcon } from 'components/ImageComponent';
import LevelUpModal from './LevelUpModal';
import ReferralProgramModal from './ReferralProgramModal';

const Container = styled(Box)(({ theme }) => ({
  height: 84,
  display: 'flex',
  borderTop: `2px solid ${theme.palette.border.decorative}`,
  '@media (max-width: 600px)': {
    display: 'grid',
    gridTemplateColumns: `repeat(2, 1fr)`,
    border: `1px solid ${theme.palette.border.separator}`,
    borderRadius: 16,
    overflow: 'hidden',
    margin: 16,
    height: 'auto',
    '& > div:last-of-type': {
      gridColumn: '1 / 3',
      borderBottom: 'none',
    },
    '& > div:first-of-type': {
      borderRight: `1px solid ${theme.palette.border.separator}`,
    },
    '& > div:nth-of-type(3)': {
      borderRight: `1px solid ${theme.palette.border.separator}`,
    },
  },
}));

const StatBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.border.separator}`,

  '@media (min-width: 600px)': {
    borderRight: `2px solid ${theme.palette.border.decorative}`,
    padding: 0,
  },
}));

const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.text.secondary,
  border: 'none',

  '@media (min-width: 600px)': {
    fontSize: 14,
    fontWeight: 600,
  },
}));

const DataText = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
  border: 'none',

  '@media (min-width: 600px)': {
    fontSize: 20,
    fontWeight: 600,
  },
}));

const Stats = () => {
  const [showLevelUpModal, setShowLevelUpModal] = useStateIfMounted(false);
  const [showRPModal, setShowRPModal] = useStateIfMounted(false);
  const { state } = useContext(DashboardContext);

  const basePoints = state.data?.basePoints ?? 0;
  const totalReferralPoints = state.data?.totalReferralPoints ?? 0;
  const totalPoints = state.data?.totalPoints ?? 0;

  const boostBonus = useMemo(() => {
    const level = getLevelByXP(basePoints).level;
    return getLevel(level).boost;
  }, [state.data]);

  const bonus = useMemo(() => {
    if (!state.data) {
      return 0;
    }
    const multiplierBonus = Object.values(state.data.bonuses).reduce((acc, bonus) => {
      return acc + (bonus.multiplier - 1 || 0);
    }, 0);
    return multiplierBonus;
  }, [state.data]);

  const handleLevelUpModal = () => {
    setShowLevelUpModal(false);
  };

  const handleCloseRPModal = () => {
    setShowRPModal(false);
  };

  return (
    <>
      <Container>
        <StatBox>
          <DataText>{basePoints.toFixed(0)}</DataText>
          <InfoText>Base Amethysts</InfoText>
        </StatBox>
        <StatBox>
          <DataText>{boostBonus.toFixed(0)}%</DataText>
          <FlexCenter>
            <InfoText style={{ marginRight: 2 }}>Level up Bonus</InfoText>
            <InfoIcon sx={{ cursor: 'pointer' }} onClick={() => setShowLevelUpModal(true)} />
          </FlexCenter>
        </StatBox>
        <StatBox>
          <DataText>{(bonus * 100).toFixed(0)}%</DataText>
          <InfoText>Booster Bonuses</InfoText>
        </StatBox>
        <StatBox>
          <DataText>{totalReferralPoints.toFixed(0)}</DataText>
          <FlexCenter>
            <InfoText>Referral Amethysts</InfoText>
            <InfoIcon sx={{ cursor: 'pointer' }} onClick={() => setShowRPModal(true)} />
          </FlexCenter>
        </StatBox>
        <StatBox>
          <DataText>{totalPoints.toFixed(0)}</DataText>
          <InfoText>Total Amethysts</InfoText>
        </StatBox>
      </Container>
      {showLevelUpModal && <LevelUpModal onClose={handleLevelUpModal} />}
      {showRPModal && <ReferralProgramModal onClose={handleCloseRPModal} />}
    </>
  );
};

export default Stats;
