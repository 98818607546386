import { Box, styled, Typography } from '@mui/material';
import Modal from 'components/Modal';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 3),
}));

const CustomText = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  textAlign: 'left',
}));

const CustomList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  listStyleType: 'disc',
  paddingInlineStart: theme.spacing(4.5),
}));

const CustomListItem = styled('li')(() => ({
  padding: 0,
  margin: 0,
}));

const SpreadWordTooltipModal = ({ isOpen, onClose }) => {
  return (
    <Modal title="How it works?" isOpen={isOpen} onDismiss={onClose} width={589}>
      <Container>
        <CustomText>
          To activate Spread the word bonus, ensure that you invite the required number of friends
          as specified for each tier. Only friends who have successfully signed up for the airdrop
          will count as valid invites towards unlocking these bonuses.
          <Typography color="primary.main" fontWeight={600} fontSize={14}>
            [The bonus applies only for new referrals you gained during current season!]
          </Typography>
        </CustomText>
        <Box>
          <CustomText>Requirements to activate Spread the word bonus tiers:</CustomText>
          <CustomText>1. Level 1:</CustomText>
          <CustomList>
            <CustomListItem>
              Invite 1 friend to sign up for the airdrop to receive a 10% bonus.
            </CustomListItem>
          </CustomList>
          <CustomText>2. Level 2:</CustomText>
          <CustomList>
            <CustomListItem>
              Invite 3 friends to sign up for the airdrop to unlock a 20% bonus.
            </CustomListItem>
          </CustomList>
          <CustomText>3. Level 3:</CustomText>
          <CustomList>
            <CustomListItem>
              Invite 10 friends to sign up for the airdrop to attain a 30% bonus.
            </CustomListItem>
          </CustomList>
          <CustomText>4. Level 4:</CustomText>
          <CustomList>
            <CustomListItem>
              Invite 30 friends to sign up for the airdrop to achieve a 40% bonus.
            </CustomListItem>
          </CustomList>
          <CustomText>5. Level 5:</CustomText>
          <CustomList>
            <CustomListItem>
              Invite 100 friends to sign up for the airdrop to access the maximum 50% bonus.
            </CustomListItem>
          </CustomList>
        </Box>
      </Container>
    </Modal>
  );
};

export default SpreadWordTooltipModal;
