import { useContext } from 'react';
import { Box, useMediaQuery, Tabs as MuiTabs, Tab as MuiTab, styled } from '@mui/material';

import { DashboardContext } from 'context/DashboardContext';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  borderBottom: `1px solid ${theme.palette.border.separator}`,

  '@media (min-width: 600px)': {
    padding: theme.spacing(0, 3),
  },
}));

const TabsBar = styled(MuiTabs)(() => ({
  width: '100%',
  display: 'flex',
}));

const Tab = styled(MuiTab)(({ theme }) => ({
  flex: 1,
  maxWidth: '100%',
  fontSize: 10,
  fontWeight: 500,
  color: theme.palette.text.tertiary,
  paddingLeft: 0,
  paddingRight: 0,
  textTransform: 'uppercase',
  '&.Mui-selected': {
    color: theme.palette.text.main,
    backgroundColor: 'rgba(0, 171, 88, 0.08)',
  },
  '@media (min-width: 600px)': {
    fontSize: 14,
  },
}));

const Tabs = () => {
  const above600 = useMediaQuery('(min-width: 600px)');
  const { state, setTab } = useContext(DashboardContext);

  return (
    <Container>
      <TabsBar onChange={(_, value) => setTab(value)} value={state.tab}>
        <Tab value="overview" label="Overview" />
        <Tab value="bonus" label="Boosters" />
        {!above600 && <Tab value="board" label="Leaderboard" />}
      </TabsBar>
    </Container>
  );
};

export default Tabs;
