import ReactGA from 'react-ga4';
import { openLink } from './openLink';
import {
  RP_X_SHARE_MESSAGE,
  POWER_TWEET_REWARD_MESSAGE,
  POWER_TWEET_SHARE_MESSAGE,
} from 'constants';

export const socialMediaShare = (refCode, marketingTag = 'Marketing', isReferral = false) => {
  ReactGA.event({
    category: 'webapp',
    action: marketingTag,
  });

  const description = encodeURIComponent(
    isReferral ? RP_X_SHARE_MESSAGE : POWER_TWEET_SHARE_MESSAGE,
  );

  openLink(`https://x.com/intent/tweet?url=${description}${refCode}`);
};

export const powerTweetMediaShare = (refCode, data, marketingTag = 'Marketing') => {
  ReactGA.event({
    category: 'webapp',
    action: marketingTag,
  });

  const description = encodeURIComponent(POWER_TWEET_REWARD_MESSAGE(data));

  openLink(`https://x.com/intent/tweet?url=${description}${refCode}`);
};
